import {
  IObserverOptions,
  IContent,
  IMenu,
  IEditorState,
  IShape,
  ImageModel,
  IAppState,
  IVisibleOptions,
  VevDispatch,
  IEmojiIcon,
  IVevImage,
  IRoute,
  IPage,
} from 'vev';
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  createContext,
  useMemo,
} from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import system from '../system';
import { StateContext, getState } from './state';
import { Intersection, isString, root, findParent } from '../utils';
import { raf } from '../utils/animation';
import widgetPlaceHolder from '../components/placeholder-widget';

const ModelContext = createContext<IContent>({ key: '' });
export const ModelProvider = ModelContext.Provider;

const useForceUpdate = () => {
  const u = useState<number>()[1];
  return () => u(performance.now());
};

let queueWidgetTimeout: number;
const queueWidgetUpdate: Function[] = [];

function doQueueWidgetUpdate(cb: () => void) {
  if (queueWidgetUpdate.indexOf(cb) === -1) queueWidgetUpdate.push(cb);
  if (!queueWidgetTimeout) queueWidgetTimeout = self.setTimeout(doWidgetUpdate, 10);
}

function doWidgetUpdate() {
  queueWidgetTimeout = 0;
  for (const cb of queueWidgetUpdate) cb();
  queueWidgetUpdate.length = 0;
}

export function usePages(): [IPage[], string | undefined] {
  return useGlobalStore((s) => [s.pages, s.dir]);
}

/**
 * `useWidget` watches and returns the given widget model
 */
export function useWidget(type: string | undefined): null | typeof React.Component {
  const path = useGlobalStore((state) => (type && state.pkg[type]) || type, [type]);
  const forceUpdate = useForceUpdate();
  const widgetRef = useRef<any>();
  useEffect(() => {
    if (path) {
      return system.watch(path, () => doQueueWidgetUpdate(forceUpdate));
    }
    return () => {};
  }, [path]);

  const mod = path && system.syncImport(path);

  if (mod) widgetRef.current = (type && mod[type]) || mod.default;

  const widget = widgetRef.current;
  if (widget && !widget.displayName) widget.displayName = type;
  return widget || widgetPlaceHolder;
}

/**
 * `useIntersection` advanced alternative for useVisible to detect the intersection % of a html ref element
 * Possible to pass options to offset the viewport size and how many intersection steps it should do
 */
export function useIntersection<T extends Element>(
  ref: React.RefObject<T>,
  options?: IObserverOptions,
): false | IntersectionObserverEntry {
  const [visible, setVisible] = useState<IntersectionObserverEntry | false>(false);
  useEffect(() => {
    const { current } = ref;
    if (current) {
      Intersection.add(current, (e) => setVisible(e), options);
      return () => Intersection.remove(current);
    }
  }, [ref.current, options && options.offsetBottom, options && options.offsetTop]);

  return visible;
}

/**
 * `useVisible` detects when the html ref element is in viewport
 * Possible to pass options to offset the viewport size
 */
export function useVisible<T extends Element>(
  ref: React.RefObject<T>,
  options?: IVisibleOptions,
): boolean {
  const intersection = useIntersection(ref, options);
  return intersection && intersection.isIntersecting;
}

/**
 * `useInterval` simple way of starting a set interval in component
 */
export function useInterval(callback: () => any, delay: number) {
  const savedCallback = useRef<Function>();
  savedCallback.current = callback;

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

/**
 * `useHover` a simple way to detect hover of a html element
 * @example ```
 * const [hover, bind] = useHover();
 * <div {...bind}>{hover ? 'Is hovering' : 'not hovering'}</div>
 * ```
 */
export function useHover(): [
  boolean,
  {
    onMouseEnter: (e: React.MouseEvent) => void;
    onMouseLeave: (e: React.MouseEvent) => void;
  },
] {
  const [isHovered, setHovered] = React.useState(false);
  const bind = {
    onMouseEnter: () => setHovered(true),
    onMouseLeave: () => setHovered(false),
  };
  return [isHovered, bind];
}

/**
 * `useSize` watches and returns the size of a html ref element,
 */
export function useSize<T extends Element>(
  ref: React.RefObject<T>,
  onChange?: (size: { width: number; height: number }) => void,
): { width: number; height: number } {
  const [size, setSize] = useState<{ width: number; height: number }>(() => {
    const el = ref.current;
    return { width: el ? el.clientWidth : 0, height: el ? el.clientHeight : 0 };
  });

  const [observer] = useState(
    () =>
      new ResizeObserver((entries) => {
        const rect = entries[0] && entries[0].contentRect;
        if (rect) {
          const size = { width: rect.width, height: rect.height };
          if (onChange) onChange(size);
          setSize(size);
        }
      }),
  );

  useLayoutEffect(() => {
    const el = ref.current;
    if (el) {
      observer.observe(el);
      return () => observer.unobserve(el);
    }
  }, [ref.current]);

  useEffect(() => () => observer.disconnect(), []);

  return size;
}

/**
 * `useFrame` can by use for animation or style changes
 *  Will trigger the callback every animation frame
 */
export function useFrame(callback: (timestamp?: number) => void, deps?: ReadonlyArray<any>): void {
  useLayoutEffect(() => {
    callback(performance.now());
    return raf(callback, true);
  }, deps);
}

/**
 * `useImage` watches and returns a give image with the given key
 */
export function useImage(imageKey: string): ImageModel | undefined {
  const images = useGlobalStore((state) => state.images);
  return images[imageKey];
}

/**
 * `useIcon` watches and returns the given icon,
 *  @param{iconKey} string - is the key of the icon you wish to get, icon keys can be found in the widgets manifest
 */
export function useIcon(
  iconKey: string | IShape | IEmojiIcon | IVevImage,
): IShape | string | undefined | IEmojiIcon | IVevImage {
  const model = useModel();
  const shapes = useGlobalStore((state) => state.shapes);
  if (!isString(iconKey)) return iconKey;
  if (model) {
    if (model.icons) return model.icons[iconKey];
  }

  if (!shapes[iconKey]) {
    const defaultVevShape = [
      128,
      128,
      'M103 38.8L84 89.2h-8.6l-19-50.5h8l12.2 32.1 2.8 10.9h.5l2.8-10.9 12.2-32.1 8.1.1zM44 68.4H28v20.8h16.4V85H32.9v-4.5h10.3v-4.2H32.9v-3.5H44v-4.4zm-7.9-12.7l-1.2-4.5-4.4-12.5H25l7.5 20.8h7.3l7.5-20.8h-5.5l-4.4 12.5-1.2 4.5h-.1z',
    ] as IShape;
    return defaultVevShape;
  }

  return shapes[iconKey];
}

/**
 * `useEditorState` watches and returns the editor state for the current model
 */
export function useEditorState(): IEditorState {
  const model = useModel();
  const editor = useGlobalStore((state) => state.editor, []);

  let selected = false,
    rule = 'host',
    disabled = false;
  if (editor && model) {
    if (
      (editor.selection && editor.selection.indexOf(model.key) !== -1) ||
      editor.action === model.key
    ) {
      selected = true;
      rule = editor.rule;
    }
    disabled = editor.disabled;
  }
  return useMemo(() => ({ selected, disabled, rule }), [selected, rule, disabled]);
}

const emptyMenu = { children: [], title: '' };
/**
 * `useMenu` watches and returns the given menu
 * if no menu key defined the primary menu will be used
 */
export function useMenu(menuKey?: string): IMenu {
  return useGlobalStore(
    ({ menus, primaryMenu }) => {
      const key = menuKey || primaryMenu;
      return (menus && key && menus[key]) || emptyMenu;
    },
    [menuKey],
  );
}

/**
 * `useModel` watches and returns the current or given content model
 */
export function useModel(key?: string): IContent | undefined {
  const contextState = useContext(ModelContext);
  if (!key) return contextState;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const globalState = useGlobalStore(
    (state) => {
      for (const model of state.models) {
        if (model.key === key) {
          return model;
        }
      }
    },
    [key],
  );

  return globalState;
}

/**
 * `useScrollTop` watches and returns the current scroll top
 * if percentage argument is true the scroll progress is return as float between [0,1]
 */
export function useScrollTop(percentage?: boolean): number {
  return useGlobalStore(
    ({ scrollTop, viewport }) =>
      percentage
        ? viewport.scrollHeight <= viewport.height
          ? 1
          : Math.max(0, scrollTop / (viewport.scrollHeight - viewport.height))
        : scrollTop,
    [percentage],
  );
}

/**
 * `useDevice` watches and returns the id of the current device
 */
export function useDevice(): string {
  return useGlobalStore((state) => state.device, []);
}

/**
 * `useViewport` watches and returns the viewport (window width,height, and scrollHeight)
 */
export function useViewport(): { width: number; height: number; scrollHeight: number } {
  return useGlobalStore((state) => state.viewport, []);
}

/**
 * `useZoom` watches and returns the zoom amount
 */
export function useZoom(): number {
  return useGlobalStore(({ zoom }) => zoom, []);
}

/**
 * `useRoute` watches and returns for changes to the current route
 */
export function useRoute(): IRoute {
  return useGlobalStore((state) => state.route, []);
}

/**
 * `useLiveEvent` can be used to watch all events on a give tag of a certain type happening inside a given element
 * @example useLiveEvent('a', 'click', document.body, () => console.log('LINK CLICKED'));
 */
export function useLiveEvent<K extends keyof HTMLElementEventMap>(
  tagname: string,
  eventName: K,
  rootEl: React.RefObject<HTMLElement | undefined> | HTMLElement = root,
  callback: (ev: HTMLElementEventMap[K], source: HTMLElement) => any,
  deps?: any[],
) {
  useEffect(() => {
    const el = rootEl instanceof Element ? rootEl : rootEl.current;

    const handler = (e: HTMLElementEventMap[K]) => {
      const target = findParent(tagname.toUpperCase(), (e.target || e.srcElement) as HTMLElement);
      if (target) callback(e, target);
    };

    if (el) {
      el.addEventListener(eventName, handler);
    }
    return () => {
      if (el) {
        el.removeEventListener(eventName, handler);
      }
    };
  }, deps);
}

function hasChanged<U>(current: U, next: U, debugLabel?: string): boolean {
  if (Array.isArray(current) && Array.isArray(next)) {
    if (current.length !== next.length) return true;
    let i = current.length;
    while (i--) {
      if (current[i] !== next[i]) {
        if (debugLabel) console.log(`#${debugLabel}: change to array index ${i}`);
        return true;
      }
    }

    return false;
  }
  if (debugLabel && next !== current) {
    if (debugLabel) console.log(`#${debugLabel}: change to value`);
  }
  return next !== current;
}

/**
 * `useGlobalStore` gives access to the global state of the app + global dispatcher
 * The mapper function is used to pick which attributes to watch, and re-ender only happens if the given attributes changes
 * The mapper function also give access to the package state dispatch to store new variables in the
 * @example
 *    const [route, dispatch] = useGlobalStore((store, dispatch) => [store.route, dispatch]);
 *    <div onClick={() => dispatch('route', {pageKey: newPageKey})}>
 */
export function useGlobalStore<U>(
  mapper: (store: IAppState, dispatch: VevDispatch) => U,
  deps?: any[],
  debugLabel?: string,
): U {
  const state = useRef<U>();
  const [listen, dispatch, uid] = useContext(StateContext);
  if (!state.current) state.current = mapper(getState(uid), dispatch);
  const [, forceUpdate] = useState<number>();

  useEffect(() => {
    return listen((s) => {
      const next = mapper(s, dispatch);
      if (hasChanged(state.current, next, debugLabel)) {
        state.current = next;
        forceUpdate(Date.now());
      }
    });
  }, deps || []);
  return state.current;
}

/**
 * `useStore` can be used to share state between widgets in the same package
 * The mapper function is used to pick which attributes to watch, and re-ender only happens if the given attributes changes
 * The mapper function also give access to the package state dispatch to store new variables in the
 * @example
 *    const [count, dispatch] = useStore((store, dispatch) => [store.count, dispatch]);
 *    <div onClick={() => dispatch('count', count+1)}>
 */
export function useStore<T extends {}, U>(
  mapper: (store: T, dispatch: (action: string, payload: any) => void) => U,
  deps?: any[],
): U {
  const model = useModel();
  const type = model && model.type;
  return useGlobalStore((state, dispatch) => {
    const pkg = (type && state.pkg[type]) || type;
    const pkgState = ((pkg && state.pkgStores[pkg]) || {}) as T;
    return mapper(pkgState, (action, payload) => dispatch(action, payload, pkg));
  }, deps);
}

/**
 * `useGlobalStateRef` returns a mutable ref to the global IAppState for the current context
 * Note that `useGlobalStateRef()` It will not trigger a render when app state changes,
 * but it can be used to improve the performance of components because you'll have access to the latest version of the state in all useEffects or useFrames
 * without needing to re-render your component
 */
export function useGlobalStateRef(): [React.MutableRefObject<IAppState>, VevDispatch] {
  const [listen, dispatch, uid] = useContext(StateContext);
  const state = useRef<IAppState>(getState(uid));

  useEffect(() => listen((s) => (state.current = s)), []);
  return [state, dispatch];
}
