import anime from 'animejs';
import {
  doc,
  docEl,
  getById,
  getScrollHeight,
  getScrollTop,
  getViewWidth,
  global,
  isString,
  root,
  getViewHeight,
} from '../utils';

/** Scroll to animation  speed (px / s) */
const SCROLL_SPEED = 800;

// const SIZES = {
//   desktop: 1024,
//   tablet: 600,
//   mobile: 320
// };

const scrollEl = doc.documentElement || doc.scrollingElement || root || {};
const ua = typeof navigator !== 'undefined' ? navigator.userAgent.toLowerCase() : '';
const hasUa = (name: string): boolean => ua.indexOf(name) > -1;

let updateSizeTimeout: number;

/**
 * global manager handles global related events like scrolling, resize and device mode
 */
class ViewManager {
  public width: number = global.innerWidth;
  public height: number = global.innerHeight;
  public autoMode = true;
  public mode = 'desktop';
  public scrollTop = 0;
  public scrollHeight = 0;
  public scrollDir: -1 | 1 = 1;
  public rootNodeOffsetTop = 0;

  public zoom = 1;

  public get percentY(): number {
    return this.scrollTop / (this.scrollHeight - this.height);
  }

  // must check msStream because IE added iPhone to ua to get ios g-mail
  public readonly isIOS: boolean = /ipad|iphone|ipod/i.test(ua) && !(global as any).MSStream;
  public readonly isAndroid: boolean = hasUa('android');
  public readonly isFirefox: boolean = hasUa('firefox');
  public readonly isEdge: boolean = hasUa('edge');
  public readonly isIE: boolean = hasUa('msie ') || hasUa('trident/');
  public readonly isChrome: boolean = hasUa('chrome') && !this.isEdge;
  public readonly isSafari: boolean = hasUa('safari/') && !this.isChrome;
  public readonly isOpera: boolean = typeof (global as any).opr !== 'undefined';

  /** @deprecated can exist multiple roots now */
  public root: HTMLElement = document.body;

  /** @deprecated */
  public get isMobile(): boolean {
    return this.mode === 'mobile';
  }

  /** @deprecated */
  public get isTablet(): boolean {
    return this.mode === 'tablet';
  }

  /** @deprecated */
  public get isDesktop(): boolean {
    return this.mode === 'desktop';
  }

  private scrollEl: Element = scrollEl as any;

  constructor() {
    if ('createElement' in doc) {
      this.scrollTop = this.scrollEl.scrollTop;
      this.updateSize();
      global.addEventListener('resize', this.handleResize, { passive: true });
      global.addEventListener('scroll', this.handleScroll, { passive: true });
      // Start interval check of scroll height
      setInterval(this.checkScrollHeight, 250);
    }
  }

  setScrollTop(value: number) {
    window.scrollTo(0, value);
  }

  /** @deprecated */
  setMode(mode: string) {
    if (this.mode !== mode) this.mode = mode;
  }

  update() {
    this.updateSize();
  }

  getElCenteringPos(el: Element): number;
  getElCenteringPos(id: string, insideEl?: Element): number;
  getElCenteringPos(el: Element | string, insideEl?: Element): number {
    if (typeof el === 'string') el = getById(el, insideEl) as Element;
    if (!el) return 0;
    const { top, height } = el.getBoundingClientRect();
    return top - Math.max(0, this.height / 2 - height / 2);
  }

  scrollTo(top: number, noAnimation?: boolean, duration?: number): Promise<any>;
  scrollTo(
    elementId: string,
    noAnimation?: boolean,
    duration?: number,
    insideEl?: HTMLElement,
  ): Promise<any>;
  scrollTo(
    val: string | number,
    noAnimation?: boolean,
    duration?: number,
    insideEl?: HTMLElement,
  ): Promise<any> {
    if (isString(val)) val = this.scrollTop + this.getElCenteringPos(val, insideEl);
    anime.remove('html, body');
    const dScroll = Math.abs(val - this.scrollTop);
    if (!duration && (noAnimation || dScroll > this.height * 2)) {
      this.setScrollTop(val);
      return Promise.resolve();
    }
    return anime({
      targets: 'html, body',
      scrollTop: [this.scrollTop, val],
      easing: 'easeOutQuart',
      duration: (duration || 0) * 1000 || (dScroll / SCROLL_SPEED) * 1000,
    }).finished;
  }

  private checkScrollHeight = () => {
    this.scrollHeight = getScrollHeight();
  };

  updateSize = () => {
    this.height = getViewHeight();
    this.width = getViewWidth();
    this.scrollHeight = getScrollHeight();
    // Run update size another time to be certain that the size is correct
    // This is mainly added because IOS does not trigger window resize when done animating webview in apps
    updateSizeTimeout = self.setTimeout(this.updateSize, 600);

    // const height = docEl.clientHeight;
    // /** Using root width in case of embed */
    // const width = getViewWidth();
    // const scrollHeight = getScrollHeight();
    // clearTimeout(updateSizeTimeout);
    // // Done update i no change
    // if (
    //   !force &&
    //   width === this.width &&
    //   height === this.height &&
    //   scrollHeight === this.scrollHeight
    // ) {
    //   return (updateSizeTimeout = self.setTimeout(this.updateSize, 600));
    // }

    // this.height = height;
    // this.width = width;
    // this.scrollHeight = scrollHeight;
    // if (this.disabled) return;

    // if (this.autoMode) {
    //   let mode: string;
    //   if (width >= SIZES.desktop) mode = 'desktop';
    //   else if (width >= SIZES.tablet) mode = 'tablet';
    //   else mode = 'mobile';
    //   this.setMode(mode);
    // }
    // const size = SIZES[this.mode];
    // this.zoom = Math.round((width / size) * 100) / 100;
    // if ((!this.isFullWidth() && this.zoom > 1) || this.disabled) this.zoom = 1;
  };

  updateOffsetTop = (length: number) => {
    this.rootNodeOffsetTop = length;
  };

  private handleScroll = (): void => {
    const scrollTop = getScrollTop();
    if (scrollTop !== this.scrollTop) {
      this.scrollDir = scrollTop < this.scrollTop ? -1 : 1;
      this.scrollTop = scrollTop;
    }
  };

  private handleResize = (): void => {
    this.updateSize();
  };
}

export default new ViewManager();
