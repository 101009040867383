import React, { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { IAppState, IPageTransition } from 'vev';
import {
  Tracking,
  useGlobalStateRef,
  useGlobalStore,
  useLiveEvent,
  View as ViewManager,
} from '../core';
import Timeline from '../model/timeline';
import { cleanPath, pageKeyByPath, pagePathByKey, updatePageHeader } from '../utils/route';
import Fixed from './fixed';
import Page from './page';
import View from './view';

const CENTER_VALUES = { x: 0, y: 0, opacity: 1, scale: 1 };

function App() {
  const [state, dispatch] = useGlobalStateRef();
  const [route, viewport] = useGlobalStore((state) => [state.route, state.viewport], []);
  const container = useRef<HTMLDivElement>(null);
  const currentPage = useRef<HTMLDivElement>(null);
  const nextPage = useRef<HTMLDivElement>(null);
  const disableRouter = useRef<boolean>();

  const { pageKey, widgetKey, transition } = route || {};
  const nextPageKey = transition ? transition.toPageKey : pageKey;

  useLayoutEffect(() => {
    dispatch('set-root', container.current ?? null);
  }, []);

  useEffect(() => {
    const onRoute = (e: PopStateEvent) => {
      disableRouter.current = true;
      dispatch('set-route-path', location.pathname);
    };
    window.addEventListener('popstate', onRoute);
    return () => window.removeEventListener('popstate', onRoute);
  }, []);

  useLiveEvent(
    'a',
    'click',
    container,
    (e, target) => {
      const path = target.getAttribute('href') || '';
      if (!path) return;

      const isInternal = /(^(#|\/)[^\/])/.test(path);
      const isEmail = /^mailto/i.test(path);
      const isPhone = /^tel/i.test(path);
      let eventCategory: string;
      // if Is internal and router enabled with not ctrol or meta key click then prevent default
      // Also prevent default if no location.host ( in editor) and internal link
      if (isInternal && (!location.host || !(e.ctrlKey || e.metaKey))) {
        e.preventDefault();

        const { route, pages, dir } = state.current;

        let tween: IPageTransition | undefined;
        if (target.dataset.tween && target.dataset.tween !== '{}') {
          try {
            tween = JSON.parse(target.dataset.tween) as IPageTransition;
            // If missing tweenIN and tweenOut then invalid tween
            if (!tween.tweenIn && !tween.tweenOut) tween = undefined;
          } catch (e) {
            console.error('Failed to parse tween', e);
          }
        }
        const [pagePath, toWidgetKey] = path.split('#');
        const toPageKey = pageKeyByPath(pagePath, pages, dir);
        const currentPageKey = (route.transition && route.transition.toPageKey) || route.pageKey;
        if (path.startsWith('#') || currentPageKey === toPageKey) {
          if (toWidgetKey) {
            ViewManager.scrollTo(toWidgetKey, undefined, undefined, container.current || undefined);
          }
          return;
        }
        const nextRoute: IAppState['route'] = tween
          ? { pageKey: currentPageKey, transition: { toPageKey, tween, toWidgetKey } }
          : { pageKey: toPageKey, widgetKey: toWidgetKey };
        dispatch('route', nextRoute);
        if (!tween) {
          ViewManager.setScrollTop(0);
        }
        eventCategory = 'Internal';
      } else if (isEmail) eventCategory = 'Email';
      else if (isPhone) eventCategory = 'Phone';
      else eventCategory = 'Outbound';

      Tracking.send('link', eventCategory + ' Link', 'click', path);
    },
    [],
  );

  useMemo(() => {
    const { pages, embed, dir } = state.current;
    const page = pages.find((p) => p.key === nextPageKey);

    if (!nextPageKey) return;

    const path = pagePathByKey(nextPageKey, pages, dir);

    if (
      !embed &&
      !disableRouter.current &&
      location.host &&
      path !== cleanPath(location.pathname)
    ) {
      history.pushState(
        { pageKey: nextPageKey, scrollTop: 0 },
        document.title,
        path + location.search + location.hash,
      );
      updatePageHeader(path);
    } else if (disableRouter.current) disableRouter.current = false;
  }, [nextPageKey]);

  useLayoutEffect(() => {
    if (widgetKey) {
      ViewManager.scrollTo(widgetKey, true);
      dispatch('route', { pageKey });
    }
  }, [widgetKey]);

  useLayoutEffect(() => {
    if (transition) {
      const { scrollTop, embed } = state.current;
      const { toPageKey, toWidgetKey, tween } = transition;
      if (toWidgetKey) ViewManager.scrollTo(toWidgetKey);
      const { current } = currentPage;
      const next = nextPage.current;

      const timeline = new Timeline(() => {
        ViewManager.setScrollTop(0);
        dispatch('route', { pageKey: toPageKey });
      });

      nextPage.current?.removeAttribute('style');

      if (currentPage.current) {
        currentPage.current.removeAttribute('style');
        currentPage.current.scrollTop = scrollTop;
      }

      const { width, height } = viewport || {};
      timeline.scaling({ x: width, y: height });

      if (nextPage.current && tween.tweenIn) {
        timeline.toValues(nextPage.current, tween.tweenIn, CENTER_VALUES);
      }

      if (currentPage.current && tween.tweenOut) {
        timeline.fromValues(currentPage.current, tween.tweenOut, CENTER_VALUES);
      }

      timeline.play();

      if (!embed) dispatch('scrollTop', 0);

      return () => {
        if (current) {
          timeline.remove(current);
        }

        if (next) {
          timeline.remove(next);
        }

        timeline.reset();
        if (current) current.removeAttribute('style');
      };
    }
  }, [transition]);

  return (
    <View ref={container}>
      <Page
        key={pageKey || ''}
        className={transition && (!transition.tween.inFront ? 'front pin' : 'pin')}
        id={pageKey || ''}
        active
        ref={currentPage}
      />
      {transition && (
        <Page key={transition.toPageKey} className="pin" id={transition.toPageKey} ref={nextPage} />
      )}
      {pageKey && <Fixed pageKey={pageKey} />}
    </View>
  );
}

export default React.memo(App);
