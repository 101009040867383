import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { DeviceSettings } from 'vev';
import {
  getScrollHeight,
  getScrollTop,
  scrollEl,
  useFrame,
  useGlobalStateRef,
  useGlobalStore,
} from '../core';
import ViewManager from '../manager/view';

interface ViewProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

function isConditionRule(rule: CSSRule): rule is CSSConditionRule {
  return rule.type === 4;
}

function getDeviceName(cssText: string): string {
  const m = cssText.match(/--vev-device:\s*"(.+?(?="))"/);
  return (m && m[1]) || '';
}

/** This function automatically detects css media changed and extracts --vev-device css var from the media */
function useCSSDevice(styles = document.styleSheets) {
  const medias = useMemo(() => {
    const res: { [device: string]: MediaQueryList } = {};
    for (let i = 0; i < styles.length; i++) {
      const rules = styles[i].cssRules || [];

      for (let i = 0; i < rules.length; i++) {
        const rule = rules[i];
        if (isConditionRule(rule)) {
          const device = getDeviceName(rule.cssText);
          if (device) res[device] = matchMedia(rule.conditionText);
        }
      }
    }
    return res;
  }, [styles]);

  const [device, setDevice] = useState<string>(() => {
    for (const key in medias) {
      if (medias[key].matches) return key;
    }
    return Object.keys(medias)[0];
  });

  useEffect(() => {
    const handleChange = (device: string) => (e: MediaQueryListEvent) => {
      if (e.matches) setDevice(device);
    };
    for (const key in medias) medias[key].onchange = handleChange(key);
    return () => {
      for (const key in medias) medias[key].onchange = null;
    };
  }, [medias]);

  return device;
}

function findDevice(devices: DeviceSettings[], width: number): DeviceSettings {
  return (
    devices.find((device, i, arr) => {
      let [, minWidth] = device.columnWidth;
      minWidth += (device.gutter || 0) * 2;
      return width >= minWidth || i === arr.length - 1;
    }) || devices[0]
  );
}

function View({ children, style }: ViewProps, ref: React.Ref<HTMLDivElement>) {
  const [project, editor, settings, device] = useGlobalStore((state) => [
    state.project,
    state.editor,
    state.settings,
    state.device,
  ]);
  const [stateRef, dispatch] = useGlobalStateRef();

  const scrollHeightRef = useRef<number>(-1);
  if (scrollHeightRef.current === -1) scrollHeightRef.current = scrollEl.scrollHeight;
  const scrollTopRef = useRef<number>(-1);
  if (scrollTopRef.current === -1) scrollTopRef.current = scrollEl.scrollTop;

  // const { width } = useSize(ref);

  useFrame(() => {
    const scrollHeight = getScrollHeight();
    if (scrollHeight !== scrollHeightRef.current) {
      scrollHeightRef.current = scrollHeight;
      dispatch('update-viewport');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => dispatch('update-viewport');
    const handleScroll = () => {
      const top = getScrollTop();
      if (top !== scrollTopRef.current) {
        dispatch('scrollTop', top);
      }
    };

    handleResize();

    self.addEventListener('resize', handleResize, { passive: true });
    self.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      self.removeEventListener('resize', handleResize);
      self.removeEventListener('scroll', handleScroll);
    };
  }, [settings]);

  useLayoutEffect(() => {
    if (!project || editor?.disabled || editor?.preRender) return;

    const styleEL = document.createElement('style');
    document.body.appendChild(styleEL);

    let prevZoom: number;
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0] && entries[0].contentRect;

      const { settings, device: deviceMode, editor } = stateRef.current;

      if (!editor || (!editor.disabled && !editor.preRender)) {
        const device = findDevice(settings.devices, width);
        if (device.mode !== deviceMode) dispatch('device', device.mode);
        const [maxColumnWidth, minColumnWidth] = device.columnWidth;
        const gutter = device.gutter || 0;
        const fullWidth = device.scaling;
        const size = (fullWidth ? maxColumnWidth : minColumnWidth) + gutter * 2;

        let zoom = Math.round((width / size) * 100) / 100;
        if (!fullWidth && zoom > 1) zoom = 1;

        if (prevZoom === zoom) return;
        /** Need to update deprecated ViewManager */
        ViewManager.zoom = prevZoom = zoom;
        dispatch('zoom', zoom);
        // Text size adjust can not be 100% because then samsung internet falls back on browsers default text size adjust which is not necessarily 100%
        const textSizeAdjust = `${Math.round((zoom === 1 ? 0.99 : zoom) * 100)}%`;
        styleEL.innerText =
          `.p${project} .__p,.p${project} .__f{` +
          `-webkit-text-size-adjust: ${textSizeAdjust};` +
          `-ms-text-size-adjust: ${textSizeAdjust};` +
          `-moz-text-size-adjust: ${textSizeAdjust};` +
          `text-size-adjust: ${textSizeAdjust};` +
          (zoom !== 1 ? `zoom: ${zoom};` : '') +
          (zoom !== 1 ? `-moz-transform: scale(${zoom});` : '') +
          '}';
      }
    });

    if ((ref as React.MutableRefObject<HTMLDivElement>).current) {
      observer.observe((ref as React.MutableRefObject<HTMLDivElement>).current);
    }

    return () => {
      observer.disconnect();
      styleEL.remove();
    };
  }, [project, editor?.disabled]);

  useLayoutEffect(() => {
    const classes = ['__vev', 'p' + project, device];

    if (ViewManager.isIOS) classes.push('ios');
    if (ViewManager.isAndroid) classes.push('android');
    if (ViewManager.isIE) classes.push('ie');
    if (ViewManager.isChrome) classes.push('chrome');
    if (ViewManager.isFirefox) classes.push('firefox');
    if (ViewManager.isOpera) classes.push('opera');
    // Edge tries to be safari
    if (ViewManager.isEdge) classes.push('edge');
    else if (ViewManager.isSafari) classes.push('safari');

    (ref as React.MutableRefObject<HTMLDivElement>).current?.setAttribute(
      'class',
      classes.join(' '),
    );
  }, [project, device]);

  return (
    <div className={'p' + project + ' __vev'} style={style} ref={ref}>
      {children}
    </div>
  );
}

export default React.forwardRef(View);
