import React, { forwardRef, LegacyRef, useEffect, useRef } from 'react';
import { useGlobalStateRef, useGlobalStore } from '../../core';
import { IAppState, IFileLink } from 'vev';
import { loadPage, pagePathByKey } from '../../utils/route';

interface ILink {
  mode: 0 | 1 | 2 | 3 | 4 | 5;
}

interface IPageLink extends ILink {
  page: string;
  tweenIn?: any;
  tweenOut?: any;
  inFront?: any;
}

interface IWidgetLink extends IPageLink {
  widget: { key: string; page: string };
}

interface IPhoneLink extends ILink {
  phone: string;
}

interface IEmailLink extends ILink {
  email: string;
  subject?: string;
}

interface IExternalLink extends ILink {
  href: string;
  target: boolean;
  nofollow: boolean;
  sponsored: boolean;
}

interface LinkProps {
  to: ILink;
  className?: string;
  style?: { [attr: string]: any };
  children: React.ReactNode;
}

export function getLinkUrl(link: ILink, state: IAppState): string {
  if (!link) return '#';

  switch (link.mode) {
    case 0: {
      const pageKey = (link as IPageLink).page;
      return pagePathByKey(pageKey, state.pages, state.dir);
    }
    case 1:
      if ((link as IWidgetLink).widget) {
        const { page, key } = (link as IWidgetLink).widget;
        return `${pagePathByKey(page, state.pages, state.dir)}#${key}`;
      }
      return '';
    case 3: {
      const { email, subject } = (link || {}) as IEmailLink;
      return 'mailto:' + email + (subject ? `?subject=${subject}` : '');
    }
    case 4:
      return 'tel:' + (link as IPhoneLink).phone;
    case 5:
      // @ts-ignore
      return link?.file?.url;
    default:
      return (link as IExternalLink).href || `#`;
  }
}

export function getLinkTween(link: ILink): string | false {
  if ((link.mode <= 1 && (link as IPageLink).tweenIn) || (link as IPageLink).tweenOut) {
    return JSON.stringify({
      inFront: (link as IPageLink).inFront,
      tweenIn: (link as IPageLink).tweenIn,
      tweenOut: (link as IPageLink).tweenOut,
    });
  }

  return false;
}

function Link({ to, children, ...rest }: LinkProps, ref: LegacyRef<HTMLAnchorElement>) {
  const href = useGlobalStore((state) => to && getLinkUrl(to, state), [to]);
  const [state, dispatch] = useGlobalStateRef();
  const r = useRef<HTMLAnchorElement | null>(null);
  // let href: undefined | string,
  const tween: string | false = to ? getLinkTween(to) : false;
  const pageKey = (to as IPageLink)?.page;

  useEffect(() => {
    if (pageKey) loadPage(pageKey, state.current, dispatch);
  }, [pageKey]);

  useEffect(() => {
    if (to && r.current) {
      r.current.href = href;
    }
  });

  const rel: string[] = [];
  if (to && (to as IExternalLink).nofollow) rel.push('nofollow');
  if (to && (to as IExternalLink).sponsored) rel.push('sponsored');

  const attrs: React.AnchorHTMLAttributes<HTMLAnchorElement> = { href };

  if (to?.mode === 5) {
    const { download, target } = (to || {}) as IFileLink;
    if (!target || target === 'download') {
      attrs.download = download || true;
    }

    if (target === 'blank' || !target || target === 'download') {
      attrs.target = '_blank';
      rel.push('nofollow');
    }
  } else if (to?.mode === 3 || (to as IExternalLink)?.target) {
    attrs.target = '_blank';
  }

  if (rel.length) attrs.rel = rel.join(' ');

  return (
    <a key={href} ref={r} data-tween={tween} {...rest} {...attrs}>
      {children}
    </a>
  );
}
export default React.memo(forwardRef(Link));
