import React, { useEffect } from 'react';
import { useGlobalStateRef, useModel } from '../core/hooks';
import { loadPage } from '../utils/route';
import WidgetNode from './widget-node';

interface PageProps {
  id: string;
  className?: string;
  active?: boolean;
}

function Page({ id, className, active }: PageProps, ref: React.Ref<HTMLDivElement>) {
  const model = useModel(id);
  const [state, dispatch] = useGlobalStateRef();

  useEffect(() => {
    if (!model) loadPage(id, state.current, dispatch);
  }, [model, id, state]);

  return (
    <div className={'__p' + (className ? ' ' + className : '')} id={id} ref={ref}>
      {model?.children?.map((key) => (
        <WidgetNode key={key} id={key} section />
      ))}
    </div>
  );
}

export default React.memo(React.forwardRef(Page));
